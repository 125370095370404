import React from 'react';
import { MinimalLayout } from '../layouts/MainLayout';
import { Button, styled, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  maxWidth: '800px',
  textAlign: 'center',
  paddingTop: '5rem',
  margin: '0 auto'
});
export function NotFoundView() {
  return (
    <MinimalLayout>
      <Container>
        <Typography variant='h2'>404</Typography>
        <Typography variant='body1'>
          You are trying to access removed user details, removed preset details or page that does not exist.
        </Typography>
        <div>
          <Button startIcon={<Home />} size='large' variant='contained' color='primary' href='/'>
            Go back home
          </Button>
        </div>
      </Container>
    </MinimalLayout>
  );
}
